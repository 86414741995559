import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormHelperText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { AppContext } from "../../contexts/AppContext";
import { IBidder } from "../../interfaces/bidders/IBidder";
import { unsuspendBidder } from "../../services/BidderService";
import * as BidderService from "../../services/BidderService";
import { suspensionStatusDescription } from "../../enums/SuspensionStatusType";
import * as Styles from "./styles/BidderDetailsStyles";

interface IBidderUnsuspendDialogProps {
  isOpen: boolean;
  closeDialog: () => void;
  bidder: IBidder;
  setBidder: React.Dispatch<React.SetStateAction<IBidder>>;
}

export const BidderUnsuspendDialog = ({ isOpen, closeDialog, bidder, setBidder }: IBidderUnsuspendDialogProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const context = React.useContext(AppContext);
  const classes = Styles.BidderDetailsStyles();

  const [reason, setReason] = useState("");
  const [checkedList, setCheckedList] = useState<string[]>([]);
  const [performingAction, setPerformingAction] = useState(false);

  const doUnsuspendBidder = async () => {
    setPerformingAction(true);
    try {
      await unsuspendBidder(bidder.id, reason, checkedList);
      handleClose();
      const updatedBidder = await BidderService.GetBidder(bidder.id);
      if (updatedBidder.parsedBody) setBidder(updatedBidder.parsedBody);
    } catch {
      enqueueSnackbar("Unsuspending the bidder failed. Please try again.", { variant: "error" });
    } finally {
      setPerformingAction(false);
    }
  };

  const handleCheckboxChange = (id: string, checked: boolean) => {
    if (checked) {
      setCheckedList([...checkedList, id]);
    } else {
      setCheckedList(checkedList.filter(i => i !== id));
    }
  };

  const handleClose = () => {
    setReason("");
    setCheckedList([]);
    closeDialog();
  };

  const isValidMemberOrE2e = (memberId: string) => {
    return (
      memberId === context?.memberId || //The member who added the suspension
      bidder.memberId === context?.memberId || //The member who the bidder is registered to
      context?.isE2eMember //An e2e member user
    );
  };

  const tableCellClass = (disabled: boolean) => {
    if (disabled) return `${classes.auctionTableCell} ${classes.disabled}`;
    return classes.auctionTableCell;
  };

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="md">
        <DialogTitle id="form-dialog-title">Unsuspend Bidder</DialogTitle>
        <DialogContent>
          {bidder.suspensionDetails.length > 0 && (
            <>
              <DialogContentText>Please enter the reason why you are unsuspending this bidder</DialogContentText>
              <TextField
                autoFocus
                id="name"
                label="Unsuspension Reason"
                value={reason}
                onChange={target => setReason(target.target.value)}
                fullWidth
                multiline
                required
              />

              <DialogContentText style={{ marginTop: "24px" }}>Please select the suspensions you want to remove</DialogContentText>
              <FormHelperText>Current suspensions against bidder</FormHelperText>
              <TableContainer>
                <Table>
                  <TableBody>
                    {bidder.suspensionDetails?.map(suspension => (
                      <Tooltip title={!isValidMemberOrE2e(suspension.memberId) ? "You do not have permission to remove this suspension" : ""}>
                        <TableRow className={classes.rows}>
                          <TableCell padding="none">
                            <Checkbox
                              color="primary"
                              value={suspension.id}
                              onChange={e => handleCheckboxChange(e.target.value, e.target.checked)}
                              disabled={!isValidMemberOrE2e(suspension.memberId)}
                            />
                          </TableCell>
                          <TableCell className={tableCellClass(!isValidMemberOrE2e(suspension.memberId))}>
                            {suspension.reason ?? suspensionStatusDescription(suspension.suspensionType)} {suspension.memberName && ( " - " + suspension.memberName)}
                          </TableCell>
                          <TableCell className={classes.auctionTableCell}>{suspension.lotReference}</TableCell>
                          <TableCell className={classes.auctionTableCell + " " + classes.noWrap}>{suspension.lotRegistrationNumber}</TableCell>
                          <TableCell className={classes.auctionTableCell}>{suspension.lotTitle}</TableCell>
                        </TableRow>
                      </Tooltip>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
          {bidder.suspensionDetails.length === 0 && <DialogContentText>There are no suspensions to remove from this bidder.</DialogContentText>}
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="primary" disabled={performingAction}>
            Cancel
          </Button>
          <Button onClick={doUnsuspendBidder} color="primary" disabled={reason === "" || checkedList.length === 0 || performingAction}>
            Unsuspend Bidder
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
