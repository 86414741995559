import * as React from "react";
import update from "immutability-helper";
import { useSnackbar } from "notistack";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import { Permission, usePermissions } from "../../services/PermissionService";
import { MemberPermission } from "../../services/MemberService";
import { LoadingComponent } from "../loading/LoadingComponent";
import { AppContext } from "../../contexts/AppContext";
import { AddAdminDialog } from "./AddAdminDialog";
import AddIcon from "@material-ui/icons/Add";
import AssignmentIcon from "@material-ui/icons/Assignment";
import { PermissionAuditDialog } from "./PermissionAuditDialog";
import { CheckBox } from "@material-ui/icons";

export const PermissionsContainer: React.FC = () => {
  const context = React.useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = React.useState(false);
  const [openCreateUser, setOpenCreateUser] = React.useState(false);
  const [openAuditRecord, setOpenAuditRecord] = React.useState(false);
  const [auditRecordId, setAuditRecordId] = React.useState<string>();

  const [permissionFilter, setPermissionFilter] = React.useState<MemberPermission>(-1);
  const [userNameFilter, setUserNameFilter] = React.useState<string>();

  const [filteredPermissions, setFilteredPermissions] = React.useState<Permission[] | null>(null);

  const [permissions, setPermissions, permissionsLoading, , putPermissions, refreshPermissions] = usePermissions(() =>
    enqueueSnackbar("Could not retrieve permissions", { variant: "error" })
  );

  React.useEffect(() => {
    if (!openCreateUser) {
      refreshPermissions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openCreateUser]);

  React.useEffect(() => {
    var filtered: Permission[] | null = permissions;
    if (permissionFilter >= 0 && filtered) {
      filtered = filtered.filter(p => p.permissionLevel === permissionFilter);
    }
    if (userNameFilter && filtered) {
      filtered = filtered.filter(p => p.name.toLowerCase().indexOf(userNameFilter.toLowerCase()) >= 0);
    }
    setFilteredPermissions(filtered);
  }, [permissions, userNameFilter, permissionFilter]);

  const hasSuperAdminPermission = () => {
    if (!context) return false;
    if (context.memberPermissions === MemberPermission.SuperAdmin) return true;
    return false;
  };

  const hasPermission = (memberPermission: MemberPermission) => {
    if (!context) return false;
    if (context.memberPermissions >= memberPermission) return true;
    return false;
  };

  const handleDialogOpen = () => {
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const handleDialogCommit = async () => {
    putPermissions().then(() => {
      handleDialogClose();
    });
  };

  const handleUserPermissionChange = (id: string, permissionLevel: any) => {
    if (permissions) {
      var permissionIndex = permissions.findIndex(c => {
        return c.userId === id;
      });

      var updatedPermission = update(permissions[permissionIndex], { permissionLevel: { $set: Number(permissionLevel) } });

      var data = update(permissions, {
        $splice: [[permissionIndex, 1, updatedPermission]],
      });

      setPermissions(data);
    }
  };

  const handleReceiveMaliciousAlertsChange = (id: string, value: boolean) => {
    if (permissions) {
      var permissionIndex = permissions.findIndex(c => {
        return c.userId === id;
      });

      var updatedPermission = update(permissions[permissionIndex], { receiveMaliciousAlerts: { $set: Boolean(value) } });

      var data = update(permissions, {
        $splice: [[permissionIndex, 1, updatedPermission]],
      });

      setPermissions(data);
    }
  };  

  const handleFilterChange = (event: any, changeType: string) => {
    if (changeType === "permission") {
      if (event.target.value === -1) {
        setPermissionFilter(-1);
      } else {
        setPermissionFilter(Number(event.target.value));
      }
    } else if (changeType === "name") {
      setUserNameFilter(event.target.value);
    }
  };

  return (
    <>
      <AddAdminDialog open={openCreateUser} setOpen={setOpenCreateUser} />
      {auditRecordId && <PermissionAuditDialog open={openAuditRecord} setOpen={setOpenAuditRecord} adminId={auditRecordId} />}
      <Grid container spacing={1}>
        <Grid item sm={4} xs={12}>
          <FormControl style={{ width: "100%" }} variant="outlined" margin="dense">
            <InputLabel variant="outlined">Permission</InputLabel>
            <Select label="Permission" value={permissionFilter} onChange={value => handleFilterChange(value, "permission")} variant="outlined">
              <MenuItem value={-1}>All</MenuItem>
              <MenuItem value={MemberPermission.None}>None</MenuItem>
              <MenuItem value={MemberPermission.BucketAdmin}>Auction Admin</MenuItem>
              <MenuItem value={MemberPermission.AccountAdmin}>Bidder Admin</MenuItem>
              <MenuItem value={MemberPermission.LiveAuctionAdmin}>Auction Manager</MenuItem>
              <MenuItem value={MemberPermission.FullMember}>Full Access</MenuItem>
              {hasSuperAdminPermission() && <MenuItem value={MemberPermission.SuperAdmin}>e2e Admin</MenuItem>}
            </Select>
          </FormControl>
        </Grid>
        <Grid item sm={4} xs={12}>
          <FormControl variant="outlined" margin="dense">
            <TextField
              style={{ top: "0", marginTop: "0px", marginBottom: "0px" }}
              margin="dense"
              placeholder={"Search Name"}
              variant="outlined"
              value={userNameFilter ? userNameFilter : ""}
              onChange={value => handleFilterChange(value, "name")}
            />
          </FormControl>
        </Grid>
        <Grid sm={4} xs={12} item style={{ textAlign: "end" }}>
          {hasPermission(MemberPermission.LiveAuctionAdmin) && (
            <Fab
              color="primary"
              aria-label="add"
              size="medium"
              variant="extended"
              onClick={() => {
                setOpenCreateUser(true);
              }}
            >
              <AddIcon />
              <Typography variant="button" display="inline">
                Create Admin User
              </Typography>
            </Fab>
          )}
        </Grid>
        <Grid item xs={12}>
          {permissionsLoading && <LoadingComponent label="Loading Permissions" />}
          {!permissionsLoading && filteredPermissions && (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Permission</TableCell>
                    <TableCell style={{ whiteSpace: "normal", wordWrap: "break-word", maxWidth: "150px"}}>Receive Malicious Alerts</TableCell>
                    <TableCell>Audit</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredPermissions && filteredPermissions.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={2} style={{ textAlign: "center" }}>
                        No administrators
                      </TableCell>
                    </TableRow>
                  )}

                  {filteredPermissions &&
                    filteredPermissions.map(row => (
                      <TableRow key={row.userId}>
                        <TableCell>
                          {row.name}

                          {context?.userId === row.userId && (
                            <div style={{ margin: "4px 0px" }}>
                              <Link style={{ textDecoration: "none" }}>
                                <InfoIcon fontSize="small" style={{ verticalAlign: "text-top", marginRight: "4px" }} />
                                You cannot change permissions on your own account.
                              </Link>
                            </div>
                          )}
                          {context?.userId !== row.userId && row.e2EAdminUser && (
                            <div style={{ margin: "4px 0px" }}>
                              <Link style={{ textDecoration: "none" }}>
                                <InfoIcon fontSize="small" style={{ verticalAlign: "text-top", marginRight: "4px" }} />
                                You cannot change permissions on the e2e Admin account.
                              </Link>
                            </div>
                          )}
                        </TableCell>
                        <TableCell>{row.email}</TableCell>
                        <TableCell>
                          <FormControl>
                            <InputLabel>Permission Level</InputLabel>
                            <Select
                              native
                              disabled={
                                context?.userId === row.userId || (!hasSuperAdminPermission() && row.permissionLevel === MemberPermission.SuperAdmin) || row.e2EAdminUser
                              }
                              value={row.permissionLevel}
                              onChange={value => {
                                handleUserPermissionChange(row.userId, value.target.value);
                              }}
                            >
                              <option value={MemberPermission.None}>None</option>
                              <option value={MemberPermission.AccountAdmin} disabled={!hasPermission(MemberPermission.AccountAdmin)}>
                                Bidder Admin
                              </option>
                              <option value={MemberPermission.BucketAdmin} disabled={!hasPermission(MemberPermission.BucketAdmin)}>
                                Auction Admin
                              </option>
                              <option value={MemberPermission.LiveAuctionAdmin} disabled={!hasPermission(MemberPermission.LiveAuctionAdmin)}>
                                Auction Manager
                              </option>
                              <option value={MemberPermission.FullMember} disabled={!hasPermission(MemberPermission.FullMember)}>
                                Full Access
                              </option>
                              <option value={MemberPermission.SuperAdmin} disabled={!hasSuperAdminPermission()}>
                                e2e Admin
                              </option>
                            </Select>
                          </FormControl>
                        </TableCell>
                        <TableCell>
                        <Checkbox    
    checked={row.receiveMaliciousAlerts ?? false}
    onChange={(event) => {
      handleReceiveMaliciousAlertsChange(row.userId, event.target.checked);
    }}
  />                                                  
                        </TableCell>
                        <TableCell>
                          <Tooltip title="View audit log">
                            <IconButton
                              aria-label="View Audit log"
                              onClick={() => {
                                setAuditRecordId(row.userId);
                                setOpenAuditRecord(true);
                              }}
                            >
                              <AssignmentIcon color="primary" />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>
        <Grid item></Grid>
      </Grid>
      <div style={{ display: "flex", justifyContent: "flex-end", margin: "20px 0" }}>
        <Box mr={2}>
          <Button
            variant="contained"
            onClick={() => {
              refreshPermissions();
            }}
          >
            Cancel
          </Button>
        </Box>
        <Box>
          <Button variant="contained" color="primary" onClick={handleDialogOpen}>
            Save Changes
          </Button>
        </Box>
      </div>
      <Dialog open={open} onClose={handleDialogClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{"Save Changes?"}</DialogTitle>
        <DialogContent>
          <p>Any changes made can't be undone once saved</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} variant="contained" disabled={permissionsLoading}>
            Cancel
          </Button>
          <Button onClick={handleDialogCommit} color="primary" variant="contained" disabled={permissionsLoading} autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
