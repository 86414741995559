import { GetClientSettings } from "./SettingsService";
import { ISalvageClientSettings } from "../interfaces/ISalvageClientSettings";
import { useVersionedData } from "./useVersionedData";
import { MemberPermission } from "./MemberService";
import { ApiService, HttpResponse } from "./ApiService";

const ClientSettings: ISalvageClientSettings = GetClientSettings();

export const usePermissions = (onError: (httpStatusCode: number, resultBody?: string) => void) =>
  useVersionedData<Permission[]>(ClientSettings.UsersPrivateApiUrl + "/Permissions", onError);

export const usePermissionAudit = (adminId: string, onError: (httpStatusCode: number, resultBody?: string) => void) =>
  useVersionedData<PermissionAudit[]>(`${ClientSettings.UsersPrivateApiUrl}/Permissions/id/${adminId}/audit`, onError);

export const CreateAdminUser = async (admin: CreateAdmin): Promise<HttpResponse<CreateAdminResult>> => {
  return await ApiService().Post<CreateAdminResult>(ClientSettings.UsersPrivateApiUrl + "/CreateAdmin/", admin);
};

export interface Permission {
  userId: string;
  name: string;
  email: string;
  e2EAdminUser: boolean;
  permissionLevel: MemberPermission;
  receiveMaliciousAlerts: boolean;
}

export interface CreateAdmin {
  firstName: string;
  lastName: string;
  email: string;
  permission: MemberPermission;
}
export interface CreateAdminResult {
  errorMessage: string;
}
export interface PermissionAudit {
  enteredByName: string;
  message: string;
  dateAdded: Date;
}

export const EmptyCreateAdmin: CreateAdmin = {
  firstName: "",
  lastName: "",
  email: "",
  permission: MemberPermission.None,
};
