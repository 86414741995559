import * as React from "react";
import {
  FormControlLabel, Checkbox, Grid, Typography, Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import { IBidder } from "../../interfaces/bidders/IBidder";
import { IValidation } from "../../interfaces/IValidation";
import { BidderDetailsForm } from "./BidderDetailsForm";
import { useState } from "react";
import { BusinessDetailsForm } from "./BusinessDetailsForm";
import { IBusiness, BusinessDefault } from "../../interfaces/bidders/IBusiness";
import { SaveChanges } from "./helpers/SaveChanges";
import moment from "moment";
import { BidderStatus, BidderStatusDescription } from "../../enums/BidderStatus";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import HistoryIcon from "@material-ui/icons/History";
import DeleteIcon from "@material-ui/icons/Delete";
import { passwordResetBidder } from "../../services/BidderService";
import { useSnackbar } from "notistack";
import { BidderSuspendDialog } from "./BidderSuspendDialog";
import { BidderUnsuspendDialog } from "./BidderUnsuspendDialog";
import * as Styles from "./styles/BidderDetailsStyles";
import { deleteBidder, GetBidder } from "../../services/BidderService";
import { useHistory } from "react-router-dom";
import { SecureArea } from "../../components/authentication/SecureArea";
import { MemberPermission } from "../../services/MemberService";

interface IProps {
  bidder: IBidder;
  setBidder: React.Dispatch<React.SetStateAction<IBidder>>;
  showSuspensionHistory: () => void;
}

export const BidderDetailsTab: React.FC<IProps> = ({ bidder, setBidder, showSuspensionHistory }) => {
  const [validation, setValidation] = React.useState<IValidation>({} as IValidation);
  const [isBusiness, setIsBusiness] = React.useState(false);
  const [business, setBusiness] = useState<IBusiness | null>(null);
  const [checkboxLabel, setCheckboxLabel] = useState("Add business details");
  const [loading, setLoading] = useState(true);
  const [dialogToDisplay, setDialogToDisplay] = useState<"suspend" | "unsuspend" | null>(null);
  const [deleteDialogState, setDeleteDialogState] = React.useState<boolean>(false);
  const history = useHistory();

  const classes = Styles.BidderDetailsStyles();
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    if (business) {
      setBidder({
        ...bidder,
        business: business,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [business]);

  if (loading) {
    if (bidder.business) {
      setIsBusiness(true);
      setBusiness(bidder.business);
      setCheckboxLabel("Remove business details");
      setLoading(false);
    } else {
      setLoading(false);
    }
  }

  const formatIdentityProvider = (idp: string) => {
    switch (idp) {
      case "https://sts.windows.net/fe63e685-3f10-4ca7-8517-094545bddfd9/":
        return "e2e";
      case "facebook.com":
        return "Facebook";
      case "google.com":
        return "Google";
      case "https://salvagemarket.b2clogin.com/727853d6-3f61-4bfe-b172-08357abb58ab/v2.0/":
        return "Salvage Market";
      case "":
        return "Identity provider not set (This is probably a test account)";
      default:
        return idp;
    }
  };

  const isBusinessChanges = () => {
    if (isBusiness) {
      setIsBusiness(false);
      setBusiness(null);
      setBidder(previous => ({
        ...previous,
        business: null,
      }));
      setCheckboxLabel("Add business details");
    } else {
      setIsBusiness(true);
      setBusiness(BusinessDefault);
      setBidder(previous => ({
        ...previous,
        business: BusinessDefault,
      }));
      setCheckboxLabel("Remove business details");
    }
  };

  const businessSwitch = () => {
    return (
      <FormControlLabel
        label={checkboxLabel}
        labelPlacement="end"
        control={
          <Checkbox
            checked={isBusiness}
            onChange={() => {
              isBusinessChanges();
            }}
          />
        }
      />
    );
  };

  const closeDialog = () => setDialogToDisplay(null);

  const deleteBidderDialog = () => {
    setDeleteDialogState(true);
  };

  const doDeleteBidder = async () => {
    try {
      var result = await deleteBidder(bidder.id);
      handleDialogClose();
      if (result.parsedBody == true) {
        // bidder has now been fully deleted
        enqueueSnackbar("Bidder deleted successfuly.", { variant: "success" });
        history.push(`/BidderSearch`);
      } else if (result.parsedBody == false) {
        // could not fully delete the bidder 
        enqueueSnackbar("The status of this bidder has been set to expired and all notifications have been disabled - as this bidder has previously interacted with the site.", { variant: "warning" });
        const updatedBidder = await GetBidder(bidder.id);
        if (updatedBidder.parsedBody) setBidder(updatedBidder.parsedBody);
      }

    } catch {
      enqueueSnackbar("Deleting the bidder failed. Please try again.", { variant: "error" });
    } finally {
    }
  };

  const handleDialogClose = () => setDeleteDialogState(false);

  const resetPassword = async () => {
    try {
      await passwordResetBidder(bidder.id);
      enqueueSnackbar("An email has been sent to the bidder", { variant: "info" });
    } catch {
      enqueueSnackbar("There was an error requesting the password reset. Please try again", { variant: "error" });
    }
  };

  const getBidderOrigin = () => {
    const now = moment();
    const endDate = bidder.subscriptionEndDate ? moment(bidder.subscriptionEndDate) : moment(0);
    const freeEndDate = bidder.freeSubscriptionEndDate ? moment(bidder.freeSubscriptionEndDate) : moment(0);
    const latest = moment.max(endDate, freeEndDate);

    if (bidder.bidderStatus === BidderStatus.ActiveFull || endDate > now) return "Paid subscription account";
    else if (bidder.bidderStatus === BidderStatus.ActiveFree || freeEndDate > now) return "Free subscription account";

    return latest.isSame(endDate) && bidder.subscriptionEndDate
        ? "Paid Expired"
        : latest.isSame(freeEndDate) && bidder.freeSubscriptionEndDate
          ? "Free Expired"
          : "Unknown";
  };

  return (
    <>
      <h3>Details</h3>
      <ul>
        <li>Authentication Method: {formatIdentityProvider(bidder.identityProvider)}</li>
        <li>Registration Date: {moment(bidder.bidderCreation).format("MMMM Do YYYY h:mm a")}</li>
        <li>Registration IP: {bidder.registrationIP}</li>
        {/* AB: Below changed as part of 2025 winter sprint for free sub to make this dynamic based on current status */}
        {/* <li>Account Origin: {bidder.accountOrigin}</li> */} 
        <li>Account: Origin: {getBidderOrigin()}</li>
        <li>Managed By: {bidder.memberName}</li>
        <li>Last Bid Date: {bidder.lastBidDate ? moment(bidder.lastBidDate).fromNow() : "Never Bid"}</li>
        <li>Last auction won date: {bidder.lastAuctionWonDate ? moment(bidder.lastAuctionWonDate).format('MMMM Do YYYY h:mm a') : "Never won an auction"}</li>
        <li>Terms and conditions accepted date: {bidder.termsAndConditionsAgreementDate ? moment(bidder.termsAndConditionsAgreementDate).format("MMMM Do YYYY h:mm a") : "Unknown: assumed date - " + moment(bidder.bidderCreation).format("MMMM Do YYYY h:mm a")}</li>
        <li>Terms and conditions version: {bidder.termsAndConditionsAgreementVersion ? bidder.termsAndConditionsAgreementVersion : 'Unknown'}</li>
      </ul>
      <Typography>
        The bidders current status is <strong>{BidderStatusDescription(bidder.bidderStatus, bidder.subscriptionEndDate)}</strong>
      </Typography>
      <Typography>
        Oustanding Bidder Suspensions registered: <strong>{bidder.suspensionDetails.length === 0 ? "No" : "Yes"}</strong>
      </Typography>

      <Button
        variant="contained"
        color="secondary"
        className={classes.suspensionButtons}
        startIcon={<ThumbDownIcon />}
        onClick={() => setDialogToDisplay("suspend")}
      >
        Suspend Bidder
      </Button>
      <BidderSuspendDialog isOpen={dialogToDisplay === "suspend"} closeDialog={closeDialog} bidder={bidder} setBidder={setBidder} />

      <Button
        variant="contained"
        color="secondary"
        className={classes.suspensionButtons}
        startIcon={<ThumbUpIcon />}
        onClick={() => setDialogToDisplay("unsuspend")}
        disabled={bidder.suspensionDetails.length === 0}
      >
        Un-Suspend Bidder
      </Button>
      <BidderUnsuspendDialog isOpen={dialogToDisplay === "unsuspend"} closeDialog={closeDialog} bidder={bidder} setBidder={setBidder} />

      <Button variant="contained" color="secondary" className={classes.suspensionButtons} onClick={showSuspensionHistory} startIcon={<HistoryIcon />}>
        Suspension History
      </Button>

      <SecureArea hideNotAuthorisedMessage={true} memberPermission={MemberPermission.SuperAdmin}>
        <Button variant="contained" color="secondary" className={classes.suspensionButtons} onClick={deleteBidderDialog} startIcon={<DeleteIcon />}>
          Delete Bidder
        </Button>
      </SecureArea>

      <Dialog open={deleteDialogState} onClose={handleDialogClose}>
        <DialogTitle>{"Confirm Bidder Deletion?"}</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete this bidder?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={() => doDeleteBidder()} variant="contained" color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <h3>Password Reset</h3>
      {formatIdentityProvider(bidder.identityProvider) === "Salvage Market" && (
        <>
          <Typography>You can send the bidder a link to the reset password page using the button below</Typography>
          <Button variant="contained" color="secondary" onClick={resetPassword}>
            Reset Password
          </Button>
        </>
      )}
      {formatIdentityProvider(bidder.identityProvider) !== "Salvage Market" && (
        <Typography>
          This bidders passwords cannot be reset by us because the bidder has chosen to login via their Facebook or Google credentials
        </Typography>
      )}
      <h3>Marketing Preferences</h3>
      <Typography>Please note you should only use this option to remove (opt out) a Bidder if they have requested it.</Typography>
      <FormControlLabel
        label="Marketing Preferences"
        labelPlacement="end"
        control={
          <Checkbox
            checked={bidder.mailingListOptIn}
            disabled={bidder.mailingListOptIn !== true}
            onChange={e => {
              setBidder({
                ...bidder,
                mailingListOptIn: e.target.checked,
              });
            }}
          />
        }
      />

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <BidderDetailsForm validation={validation} setValidation={setValidation} bidder={bidder} onBidderChange={setBidder} />
          <h3>Business Details</h3>
          {businessSwitch()}
          {isBusiness && (
            <BusinessDetailsForm
              validation={validation}
              setValidation={setValidation}
              business={business ?? BusinessDefault}
              onBusinessChange={setBusiness}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <SaveChanges bidder={bidder} setBidder={setBidder} setValidation={setValidation} />
        </Grid>
      </Grid>
    </>
  );
};
