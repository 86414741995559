import { makeStyles } from "@material-ui/styles";
import { Theme, createStyles } from "@material-ui/core";
import { amber, blue, green, grey, purple } from "@material-ui/core/colors";

export const AuctionRowStyles = makeStyles((theme: Theme) =>
  createStyles({
    image: {
      width: "200px",
      height: "auto",
    },
    tableCell: {
      padding: "4px",
    },
    infoCell: {
      minWidth: "200px",
    },
    title: {
      paddingBottom: "8px",
    },
    p: {
      fontSize: "12px",
      marginLeft: "8px",
    },
    infoSymbol: {
      color: theme.palette.primary.main,
      cursor: "default",
    },
    incompleteFlag: {
      color: purple[500],
    },
    holdingFlag: {
      color: amber[500],
    },
    liveFlag: {
      color: green[500],
    },
    notSoldFlag: {
      color: blue[500],
    },
    soldFlag: {
      color: blue[500],
    },
    archivedFlag: {
      color: grey[900],
    },
    freeBidderBadge: {
      display: 'inline-flex',
      alignItems: "center",
      justifyContent: "center",
      marginTop: '12px',
      borderRadius: 6,
      backgroundColor: '#EC6E13',
      color: 'white',
      padding: '8px',
      fontWeight: 500,
      maxWidth: '157px',
      fontSize: '13px',
      cursor: 'default'
    }
  })
);
