import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ThumbsUpDownIcon from "@material-ui/icons/ThumbsUpDown";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import HelpIcon from "@material-ui/icons/Help";
import { useTheme } from "@material-ui/core/styles";
import React from "react";
import moment from "moment";

export enum BidderStatus {
  Unknown = 0,
  NotRegistered = 1,
  AwaitingPayment = 2,
  AwaitingIdenfificaiton = 3,
  AwaitingValidation = 4,
  FailedValidation = 5,
  PotentialDuplicate = 6,
  ActiveFull = 7,
  ActiveFree = 8,
  Suspended = 9,
  SubscriptionExpired = 10,
  Member = 11,
}

export const BidderStatusDescription = (bidderStatus: BidderStatus, endDate: Date | null = null) => {
  const now = moment();
  const end = moment(endDate);

  switch (bidderStatus) {
    case BidderStatus.NotRegistered:
      return "Not Registered";
    case BidderStatus.AwaitingPayment:
      return "Awaiting Payment";
    case BidderStatus.AwaitingIdenfificaiton:
      return "Awaiting ID";
    case BidderStatus.AwaitingValidation:
      return "Awaiting Validation";
    case BidderStatus.FailedValidation:
      return "Failed Validation";
    case BidderStatus.PotentialDuplicate:
      return "Potential Duplicate";
    case BidderStatus.ActiveFull:
      return "Active Full";
    case BidderStatus.ActiveFree:
      return "Active Free";
    case BidderStatus.Suspended:
      return "Suspended";
    case BidderStatus.SubscriptionExpired:
      return "Subscription Expired"
    case BidderStatus.Member:
      return "Member";
    default:
      return "Unknown";
  }
};

export const BidderStatusIcon = (bidderStatus: BidderStatus) => {
  const theme = useTheme();

  switch (bidderStatus) {
    case BidderStatus.NotRegistered:
      return <ThumbsUpDownIcon style={{ color: theme.palette.grey[600] }} titleAccess="Not Registered" />;
    case BidderStatus.AwaitingPayment:
      return <ThumbsUpDownIcon style={{ color: "#fdd835" }} titleAccess="Awaiting Payment" />;
    case BidderStatus.AwaitingIdenfificaiton:
      return <ThumbsUpDownIcon style={{ color: "#fdd835" }} titleAccess="Awaiting ID" />;
    case BidderStatus.AwaitingValidation:
      return <ThumbsUpDownIcon style={{ color: "#673ab7" }} titleAccess="Awaiting Validation" />;
    case BidderStatus.FailedValidation:
      return <ThumbsUpDownIcon style={{ color: "#ff9800" }} titleAccess="Failed Validation" />;
    case BidderStatus.PotentialDuplicate:
      return <ThumbsUpDownIcon style={{ color: theme.palette.grey[600] }} titleAccess="Potential Duplicate Bidder" />;
    case BidderStatus.ActiveFull:
      return <ThumbUpIcon style={{ color: theme.palette.success.main }} titleAccess="Active Full" />;
    case BidderStatus.ActiveFree:
      return <ThumbUpIcon style={{ color: theme.palette.success.main }} titleAccess="Active Free" />;
    case BidderStatus.Suspended:
      return <ThumbDownIcon style={{ color: theme.palette.error.main }} titleAccess="Bidder Suspended" />;
    case BidderStatus.SubscriptionExpired:
      return <ThumbDownIcon style={{ color: "#000000" }} titleAccess="Subscription Expired" />;
    case BidderStatus.Member:
      return <ThumbUpIcon style={{ color: theme.palette.info.main }} titleAccess="Member" />;
    default:
      return <HelpIcon style={{ color: theme.palette.grey[600] }} titleAccess="Unknown" />;
  }
};
