import * as React from "react";

import { useSnackbar } from "notistack";
import { Button, FormControl, Grid, InputLabel, MenuItem, Select } from "@material-ui/core";
import { DefaultMaliciousBidderReportRequest, MemberNameAndId, useMaliciousBidderReport } from "../../services/ReportsService";
import { ContainerStyles } from "./styles/ContainerStyles";
import { LoadingComponent } from "../loading/LoadingComponent";
import { downloadCSV } from "./DownloadCsv";

export const MaliciousBidderReportContainer: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = ContainerStyles();

  const [filterRequest, setFilterRequest, apiPostAsync, filterResponse, isLoadingReport, ,] = useMaliciousBidderReport(() =>
    enqueueSnackbar("Could not retrieve malicious bidder report", { variant: "error" })
  );

  React.useEffect(() => {
    if (filterResponse) {
      downloadCSV(filterResponse.byteArray, "Malicious Bidders Report.csv");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterResponse]);

  React.useEffect(() => {
    setFilterRequest(DefaultMaliciousBidderReportRequest());
  }, []);

  const changeHandler = (event: any, property: string) => {
    if (filterRequest) {
      setFilterRequest({ ...filterRequest, [property]: event.target.value });
    }
  };

  const search = () => {
    apiPostAsync();
  };

  const reset = () => {
    if (filterRequest !== null) {
      setFilterRequest(DefaultMaliciousBidderReportRequest());
    }
  };

  return (
    <>
      {isLoadingReport && <LoadingComponent label="Loading report" />}
      {!isLoadingReport && (
        <Grid container spacing={1}>
          <Grid item xs={12} lg={2}>
            <FormControl className={classes.width100} variant="outlined" margin="dense">
              <InputLabel variant="outlined" style={{ minWidth: 220 }} >No of Days Bidding Activity</InputLabel>
              <Select
                label={"No of Days Bidding Activity"}
                onChange={value => {
                  changeHandler(value, "auctionDays");
                }}
                value={filterRequest?.auctionDays ? filterRequest.auctionDays : 5}
                variant="outlined"
              >
                <MenuItem value={1}>1 day</MenuItem>
                <MenuItem value={2}>2 days</MenuItem>
                <MenuItem value={3}>3 days</MenuItem>
                <MenuItem value={4}>4 days</MenuItem>
                <MenuItem value={5}>5 days</MenuItem>
                <MenuItem value={6}>6 days</MenuItem>
                <MenuItem value={7}>7 days</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {/* <Grid item xs={12} lg={2}>
            <FormControl className={classes.width100} variant="outlined" margin="dense">
              <InputLabel variant="outlined">Lots Won</InputLabel>
              <Select
                label={"Lots Won"}
                onChange={value => {
                  changeHandler(value, "carsWon");
                }}
                value={filterRequest?.carsWon !== undefined ? filterRequest.carsWon : 1}
                variant="outlined"
              >
                <MenuItem value={0}>0</MenuItem>
                <MenuItem value={1}>1 or more</MenuItem>
                <MenuItem value={2}>2 or more</MenuItem>
                <MenuItem value={3}>3 or more</MenuItem>
                <MenuItem value={4}>4 or more</MenuItem>
                <MenuItem value={5}>5 or more</MenuItem>
              </Select>
            </FormControl>
          </Grid> */}

          <Grid item xs={12} lg={2}>
            <FormControl className={classes.width100} variant="outlined" margin="dense">
              <InputLabel variant="outlined" >Highest Bidder On</InputLabel>
              <Select
                label={"Highest Bidder On"}
                onChange={value => {
                  changeHandler(value, "chbOnMoreThanXDays");
                }}
                value={filterRequest?.chbOnMoreThanXDays ? filterRequest.chbOnMoreThanXDays : 2}
                variant="outlined"
              >
                <MenuItem value={1}>1 or more</MenuItem>
                <MenuItem value={2}>2 or more</MenuItem>
                <MenuItem value={3}>3 or more</MenuItem>
                <MenuItem value={4}>4 or more</MenuItem>
                <MenuItem value={5}>5 or more</MenuItem>
                <MenuItem value={6}>6 or more</MenuItem>
                <MenuItem value={7}>7 or more</MenuItem>
                <MenuItem value={8}>8 or more</MenuItem>
                <MenuItem value={9}>9 or more</MenuItem>
                <MenuItem value={10}>10 or more</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} lg={2}>
            <FormControl className={classes.width100} variant="outlined" margin="dense">
              <InputLabel variant="outlined" style={{ minWidth: 220 }}>Accounts Created within last</InputLabel>
              <Select
                label={"Accounts Created within last"}
                onChange={value => {
                  changeHandler(value, "accountCreatedWithin");
                }}
                value={filterRequest?.accountCreatedWithin ? filterRequest.accountCreatedWithin : 2}
                variant="outlined"
              >
                <MenuItem value={1}>1 day</MenuItem>
                <MenuItem value={2}>2 days</MenuItem>
                <MenuItem value={3}>3 days</MenuItem>
                <MenuItem value={4}>4 days</MenuItem>
                <MenuItem value={5}>5 days</MenuItem>
                <MenuItem value={6}>6 days</MenuItem>
                <MenuItem value={7}>7 days</MenuItem>
                <MenuItem value={30}>30 days</MenuItem>
              </Select>
            </FormControl>
          </Grid>


          {/* <Grid item xs={12} lg={2}>
            <FormControl className={classes.width100} variant="outlined" margin="dense">
              <InputLabel variant="outlined">Number of Days</InputLabel>
              <Select
                label={"Number of Days"}
                onChange={value => {
                  changeHandler(value, "carsWonNumberOfDays");
                }}
                value={filterRequest?.carsWonNumberOfDays ? filterRequest.carsWonNumberOfDays : 1}
                variant="outlined"
              >
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={5}>5</MenuItem>
              </Select>
            </FormControl>
          </Grid> */}



          <Grid item xs={12} lg={1}>
            {
              <Button color="primary" className={classes.searchButton} onClick={reset}>
                Reset
              </Button>
            }
          </Grid>

          <Grid item xs={12}>
            {
              <Button className={classes.createReportButton} variant="contained" color="primary" onClick={search} fullWidth>
                Create Report
              </Button>
            }
          </Grid>
        </Grid >
      )}
    </>
  );
};
