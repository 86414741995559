import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Table,
  TableBody,
  TableCell,
  Paper,
  TableContainer,
  TableRow,
  TableHead,
  Container,
  Typography,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useAuctionHistoryIncludingPreviousListing, IAuctionHistoryBid, IAuctionHistory } from "../../../services/AuctionLotMgmtService";
import { Skeleton } from "@material-ui/lab";
import { AuctionStatus, auctionStatusGroupingString } from "../../../interfaces/auctions/IAuction";
import { bidPlacedFormatSeconds, currencyFormat } from "../../../helpers/text-format/TextFormat";
import { AuctionHistoryDialogStyles } from "../styles/AuctionHistoryDialogStyles";
import { useHistory } from "react-router-dom";
import AttachmentIcon from "@material-ui/icons/Attachment";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
interface HistoryDialog {
  open: boolean;
  setOpen: (open: boolean) => void;
  auctionId: string;
  showProxyBids: boolean;
}

export const HistoryDialog: React.FC<HistoryDialog> = ({ open, setOpen, auctionId, showProxyBids }) => {
  const classes = AuctionHistoryDialogStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [auctionHistory, isAuctionHistoryLoading, ,] = useAuctionHistoryIncludingPreviousListing(auctionId, () =>
    enqueueSnackbar("Could not retrieve auction history", { variant: "error" })
  );

  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="lg" fullWidth>
      <DialogTitle>Auction History</DialogTitle>
      <DialogContent>
        {isAuctionHistoryLoading && auctionHistory === null && (
          <Container component={Paper} className={classes.skeleton}>
            <Skeleton width="100%" height={40} />
            <Skeleton width="100%" height={40} />
            <Skeleton width="100%" height={40} />
            <Skeleton width="100%" height={40} />
            <Skeleton width="100%" height={40} />
          </Container>
        )}
        {auctionHistory &&
          auctionHistory.map((auctionHistory: IAuctionHistory, index) => (
            <TableContainer component={Paper} key={index} className={classes.tableContainer}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={2} className={classes.tableHeader}>
                      Auction history
                      {(auctionHistory.status === AuctionStatus.AuctionEndedAndWon ||
                        auctionHistory.status === AuctionStatus.AuctionCollectedRecently ||
                        auctionHistory.status === AuctionStatus.CompletedAsLotCollected) && (
                          <Typography
                            className={classes.salesDocumentLink}
                            color="primary"
                            onClick={() => window.open(`/SalesDocument/${auctionHistory.id}/`, '_blank')}
                          >
                            View sales document <AttachmentIcon className={classes.attachmentIcon} />
                          </Typography>
                        )}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow className={classes.tableRow}>
                    <TableCell>Status</TableCell>
                    <TableCell>{auctionStatusGroupingString(auctionHistory?.status)}</TableCell>
                  </TableRow>
                  <TableRow className={classes.tableRow}>
                    <TableCell>Reference</TableCell>
                    <TableCell>{auctionHistory?.referenceNumber}</TableCell>
                  </TableRow>
                  <TableRow className={classes.tableRow}>
                    <TableCell>Reg. No</TableCell>
                    <TableCell>{auctionHistory?.registrationNumber}</TableCell>
                  </TableRow>
                  <TableRow className={classes.tableRow}>
                    <TableCell>Item info</TableCell>
                    <TableCell>{auctionHistory?.title}</TableCell>
                  </TableRow>
                  <TableRow className={classes.tableRow}>
                    <TableCell>VAT</TableCell>
                    <TableCell>{auctionHistory?.vatIncluded ? "Yes" : "No"}</TableCell>
                  </TableRow>
                  <TableRow className={classes.tableRow}>
                    <TableCell>PAV</TableCell>
                    <TableCell>{currencyFormat(auctionHistory?.preAccidentValue)}</TableCell>
                  </TableRow>
                  <TableRow className={classes.tableRow}>
                    <TableCell>Cost</TableCell>
                    <TableCell>{currencyFormat(auctionHistory?.cost)}</TableCell>
                  </TableRow>
                  <TableRow className={classes.tableRow}>
                    <TableCell>Reserve</TableCell>
                    <TableCell>{currencyFormat(auctionHistory?.reservePrice)}</TableCell>
                  </TableRow>
                  <TableRow className={classes.tableRow}>
                    <TableCell>Start</TableCell>
                    <TableCell>{currencyFormat(auctionHistory?.startPrice)}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              {auctionHistory.bids && auctionHistory.bids.length > 0 ? (
                <Table>
                  <TableHead>
                    <TableRow className={classes.tableHeader}>
                      <TableCell>Bidder </TableCell>
                      <TableCell>Bid time </TableCell>
                      <TableCell>Bid </TableCell>
                      {showProxyBids && (
                        <TableCell align="center">Highest Winning Proxy Bid</TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {auctionHistory.bids &&
                      auctionHistory?.bids.map((bid: IAuctionHistoryBid, index) => (
                        <TableRow className={classes.tableRow} key={index}>
                          <TableCell>{bid.bidderName}</TableCell>
                          <TableCell>{bidPlacedFormatSeconds(bid.bidDateTime)}</TableCell>
                          <TableCell>{`${currencyFormat(bid.amount)}`}</TableCell>

                          {showProxyBids && (
                            <TableCell align="center" className={classes.tableCell}>
                              <div className={classes.proxyBidContainer}>
                                {(auctionHistory?.currentHighestBid?.bidId ?? "") === bid.bidId ? (
                                  <>
                                    <CheckCircleIcon className={classes.winning} />
                                    <Typography variant="body1" className={classes.proxyAmount}>
                                      {currencyFormat(auctionHistory?.currentHighestBid.amount)}
                                    </Typography>
                                  </>
                                ) : (
                                  <>
                                    <CancelIcon className={classes.losing} />
                                    <Typography variant="body1" className={classes.proxyAmount}>
                                      {currencyFormat(bid.proxyAmount)}
                                    </Typography>
                                  </>
                                )}
                              </div>
                            </TableCell>
                          )}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              ) : (
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={3}>No bids </TableCell>
                    </TableRow>
                  </TableHead>
                </Table>
              )}
            </TableContainer>
          ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};
