import { Link, Text, View } from "@react-pdf/renderer";
import { SalesDocument } from "../../../services/SalesDocumentService";
import { feeFormat, salesDocumentDateFormat } from "../../../helpers/text-format/TextFormat";
import { styles } from "../styles/PdfStyles";
import React from "react";

interface PaymentDetails {
  salesDocumentInfo: SalesDocument;
}
export const PaymentDetails: React.FC<PaymentDetails> = ({ salesDocumentInfo }) => (
  <View>
    <View style={styles.rowWidth100}>
      <View style={styles.splitTitle}>
        <Text style={styles.splitTitle}>Payment Details </Text>
      </View>
      <View style={styles.splitTitle}>
        <Text style={styles.redTextMediumBold}>Payment due by {salesDocumentDateFormat(salesDocumentInfo.paymentDue)}</Text>
      </View>
    </View>
    <View style={styles.row}>
      <View style={styles.paymentBlock}>
        <View style={styles.padding4}>
          <Text style={styles.paymentDetailsRedNote}>
            Payment must be made within 72 hours of the auction closing. Late payment and/or late collection may incur additional fees. The preferred
            method of payment is Bank Transfer. You must use the Reference when paying or payment will be delayed.
          </Text>
        </View>
        <View style={styles.rowWidth100}>
          <View style={styles.paymentMethodCell}>
            <Text style={styles.littleGreyText}>Payment methods</Text>
          </View>
          <View style={styles.paymentFeeCell}>
            <Text style={styles.littleGreyText}>Fee</Text>
          </View>
          <View style={styles.paymentTotalCell}>
            <Text style={styles.littleGreyText}>Total to Pay</Text>
          </View>
          <View style={styles.paymentNoteCell}>
            <Text style={styles.littleGreyText}>Notes</Text>
          </View>
        </View>

        <View style={styles.rowWidth100}>
          <View style={styles.bankMethodCell}>
            <Text style={styles.mediumText}>Bank Transfer</Text>
          </View>
          <View style={styles.bankFeeCell}>
            <Text style={styles.mediumText}>{feeFormat(salesDocumentInfo.bankTransferRate)}</Text>
          </View>
          <View style={styles.bankTotalCell}>
            <Text style={styles.mediumText}>{salesDocumentInfo.totalWithVatAndBankTransferFee}</Text>
          </View>
          <View style={styles.bankNoteCell}>
            <Text style={styles.redText}>
              {salesDocumentInfo.bankTransferNotes ? salesDocumentInfo.bankTransferNotes : `Ref: ${salesDocumentInfo.referenceNumber}`}
            </Text>
          </View>
        </View>

        <View style={styles.rowWidth100}>
          <View style={styles.paymentMethodCell}>
            <Text style={styles.mediumText}>Business Debit Card</Text>
          </View>
          <View style={styles.paymentFeeCell}>
            <Text style={styles.mediumText}>{feeFormat(salesDocumentInfo.debitCardRate)}</Text>
          </View>
          <View style={styles.paymentTotalCell}>
            <Text style={styles.mediumText}>{salesDocumentInfo.totalWithVatAndDebitCardFee}</Text>
          </View>
          <View style={styles.paymentNoteCell}>
            <Text style={styles.text}>{salesDocumentInfo.debitCardNotes}</Text>
          </View>
        </View>

        <View style={styles.rowWidth100}>
          <View style={styles.paymentMethodCell}>
            <Text style={styles.mediumText}>Cash</Text>
          </View>
          <View style={styles.paymentFeeCell}>
            <Text style={styles.mediumText}>{feeFormat(salesDocumentInfo.cashRate)}</Text>
          </View>
          <View style={styles.paymentTotalCell}>
            <Text style={styles.mediumText}>{salesDocumentInfo.totalWithVatAndCashFee}</Text>
          </View>
          <View style={styles.paymentNoteCell}>
            <Text style={styles.text}>{salesDocumentInfo.cashNotes}</Text>
          </View>
        </View>

        {salesDocumentInfo.memberGenericPaymentLink != null && salesDocumentInfo.referenceNumber != null && (
          <View style={styles.rowWidth100}>
            <View style={styles.paymentMethodCell}>
              <Text style={styles.mediumText}>Online</Text>
            </View>
            <View style={styles.paymentFeeCell}>
              <Text style={styles.mediumText}>{feeFormat(salesDocumentInfo.onlineFeeRate)}</Text>
            </View>
            <View style={styles.paymentTotalCell}>
              <Text style={styles.mediumText}>{salesDocumentInfo.totalWithVatAndOnlineFee}</Text>
            </View>
            <View style={styles.paymentNoteCell}>
              <Link src={`${salesDocumentInfo.memberGenericPaymentLink}/${salesDocumentInfo.referenceNumber}`} style={styles.payLink}>
                Pay Here
              </Link>
            </View>
          </View>
        )}

        <View style={styles.rowWidth100}>
          <View style={styles.padding4}>
            <Text style={styles.declarationText}>{salesDocumentInfo.paymentNote}</Text>
          </View>
        </View>
      </View>

      <View style={styles.bankDetails}>
        <View style={styles.padding4}>
          <Text style={styles.bankDetailsText}>Bank details</Text>
        </View>
        <View style={styles.bankDetailsColumn}>
          <View style={styles.rowWidth100}>
            <View style={styles.bankTitleCell}>
              <Text style={styles.greyText}>Ref</Text>
            </View>
            <View style={styles.bankInfoCell}>
              <Text style={styles.greyText}>{salesDocumentInfo.referenceNumber}</Text>
            </View>
          </View>

          <View style={styles.rowWidth100}>
            <View style={styles.bankTitleCell}>
              <Text style={styles.greyText}>Bank</Text>
            </View>
            <View style={styles.bankInfoCell}>
              <Text style={styles.greyText}>{salesDocumentInfo.bank}</Text>
            </View>
          </View>

          <View style={styles.rowWidth100}>
            <View style={styles.bankTitleCell}>
              <Text style={styles.greyText}>Acc name</Text>
            </View>
            <View style={styles.bankInfoCell}>
              <Text style={styles.greyText}>{salesDocumentInfo.accountName}</Text>
            </View>
          </View>

          <View style={styles.rowWidth100}>
            <View style={styles.bankTitleCell}>
              <Text style={styles.greyText}>Sort</Text>
            </View>
            <View style={styles.bankInfoCell}>
              <Text style={styles.greyText}>{salesDocumentInfo.sortCode}</Text>
            </View>
          </View>

          <View style={styles.rowWidth100}>
            <View style={styles.bankTitleCell}>
              <Text style={styles.greyText}>Account </Text>
            </View>
            <View style={styles.bankInfoCell}>
              <Text style={styles.greyText}>{salesDocumentInfo.accountNumber}</Text>
            </View>
          </View>

          <View style={styles.rowWidth100}>
            <View style={styles.bankTitleCell}>
              <Text style={styles.greyText}>Swift</Text>
            </View>
            <View style={styles.bankInfoCell}>
              <Text style={styles.greyText}>{salesDocumentInfo.swift}</Text>
            </View>
          </View>

          <View style={styles.rowWidth100}>
            <View style={styles.bankTitleCell}>
              <Text style={styles.greyText}>IBAN</Text>
            </View>
            <View style={styles.bankInfoCell}>
              <Text style={styles.greyText}>{salesDocumentInfo.iban}</Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  </View>
);
